import React from "react";
import { createUseStyles, useTheme } from "react-jss";
import classNames from "classnames";
import { ITheme } from "styles/themes/types";
import { ICommonProps, IOptionProps } from "components/shared";

const useStyles = createUseStyles((theme: ITheme) => ({
  select: {
    ...theme.typography.content.input,
    borderStyle: "solid",
    borderWidth: theme.spacing.unit / 6,
    borderColor: theme.colors.primary,
    borderRadius: theme.spacing.xSmall,
    padding: `${theme.spacing.xSmall}px ${theme.spacing.xSmall}px`,
    width: "100%",
  },
}));

interface ISelectProps extends ICommonProps {
  name: string;
  options: Array<IOptionProps>;
  defaultValue?: string;
  disabled?: boolean;
  onChange?: (event: React.ChangeEvent<HTMLSelectElement>) => void;
}

const MaistroSelect: React.FC<ISelectProps> = (props) => {
  const theme = useTheme<ITheme>();
  const classes = useStyles({ theme });

  const { onChange, ...other } = props;

  const selectOptions = props.options.map((option) => (
    <option key={option.value} value={option.value}>
      {option.label}
    </option>
  ));

  const handleChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    if (onChange) {
      onChange(event);
    }
  };

  return (
    <select
      className={classNames(classes.select)}
      onChange={handleChange}
      defaultValue={props.defaultValue}
      disabled={props.disabled}
      {...other}
    >
      {selectOptions}
    </select>
  );
};

export default MaistroSelect;
