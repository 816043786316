import React from "react";
import { createUseStyles, useTheme } from "react-jss";

import { ICommonProps } from "components/shared";
import { ITheme } from "styles/themes/types";
import Pill from "components/Pill/Pill";

const useStyles = createUseStyles((theme: ITheme) => ({
  betaPill: {
    paddingTop: 3,
    paddingBottom: 3,
    backgroundColor: theme.colors.status.completed,
    cursor: "inherit",
    borderColor: theme.colors.grey,
  },
}));

interface IBetaPillProps extends ICommonProps {
  size?: "small" | "medium" | "large";
}

const BetaPill: React.FC<IBetaPillProps> = (props) => {
  const { size } = props;
  const theme = useTheme<ITheme>();
  const classes = useStyles({ theme });

  return (
    <Pill
      className={classes.betaPill}
      value='BETA'
      size={size}
      testid='beta-pill'
    />
  );
};

BetaPill.defaultProps = {
  size: "small",
};

export default BetaPill;
