import React from "react";
import { createUseStyles, useTheme } from "react-jss";
import { ITheme } from "styles/themes/types";
import { ICommonProps } from "components/shared";

const useStyles = createUseStyles((theme: ITheme) => ({
  container: {
    display: "flex",
    justifyContent: "left",
    alignItems: "center",
    gap: theme.spacing.small,
  },
  input: {
    ...theme.typography.content.input,
    borderStyle: "solid",
    borderWidth: theme.spacing.unit / 6,
    borderColor: theme.colors.primary,
    borderRadius: theme.spacing.xSmall,
    display: "inline-block",
    height: theme.spacing.large,
    width: theme.spacing.large,
  },
}));

interface ICheckboxProps extends ICommonProps {
  label: string;
  checked?: boolean;
  disabled?: boolean;
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

const MaistroCheckBox: React.FC<ICheckboxProps> = (props) => {
  const theme = useTheme<ITheme>();
  const classes = useStyles({ theme });

  return (
    <div className={classes.container}>
      <input
        className={classes.input}
        type='checkbox'
        disabled={props.disabled}
        checked={props.checked}
        onChange={props.onChange}
      />
      <label>{props.label}</label>
    </div>
  );
};

export default MaistroCheckBox;
