import React, { useState } from "react";
import { IList } from "../data";
import { Wheel } from "react-custom-roulette";

interface IProps {
  mustSpin: boolean;
  prizeNumber: number;
  data: IList;
  onStopSpinning: (result: string) => void;
}

const MaistroWheel = (props: IProps) => {
  const outerBorderColor = "#eeeeee";
  const outerBorderWidth = 10;
  const innerBorderColor = "#30261a";
  const innerBorderWidth = 0;
  const innerRadius = 0;
  const radiusLineColor = "#eeeeee";
  const radiusLineWidth = 8;
  const fontSize = 17;
  const textDistance = 60;

  const wheelData = props.data.options.map((i) => ({
    option: i.toUpperCase(),
  }));
  const [down, setDown] = useState(0);
  const [duration, setDuration] = useState(1);
  const calcSpeed = (timeDifferenceMs: number) => {
    const speed = 100 - (timeDifferenceMs > 1000 ? 100 : timeDifferenceMs / 10);
    return ((80 / 100) * (100 - speed)) / 100 + 0.2;
  };
  return (
    <div
      onTouchStart={() => setDown(new Date().getTime())}
      onTouchEnd={() => {
        const mouseupTime = new Date().getTime();
        setDuration(calcSpeed(mouseupTime - down));
      }}
    >
      <Wheel
        mustStartSpinning={props.mustSpin}
        prizeNumber={props.prizeNumber}
        data={wheelData}
        backgroundColors={props.data.colors.map((c) => c.background)}
        textColors={props.data.colors.map((c) => c.text)}
        fontSize={fontSize}
        outerBorderColor={outerBorderColor}
        outerBorderWidth={outerBorderWidth}
        innerRadius={innerRadius}
        innerBorderColor={innerBorderColor}
        innerBorderWidth={innerBorderWidth}
        radiusLineColor={radiusLineColor}
        radiusLineWidth={radiusLineWidth}
        spinDuration={duration}
        // perpendicularText
        textDistance={textDistance}
        onStopSpinning={() => {
          props.onStopSpinning(props.data.options[props.prizeNumber]);
          setDuration(1);
        }}
      />
    </div>
  );
};
export default MaistroWheel;
