export interface IList {
  name: string;
  colors: IOptionColor[];
  options: string[];
}

interface IListDefaults {
  colors: IOptionColor[];
}

export interface IOption {
  option: string;
  style?: { textColor: string };
}

export interface IOptionColor {
  background: string;
  text: string;
}

const defaultColors: IOptionColor[] = [
  { background: "#ff8f43", text: "#000000" },
  { background: "#70bbe0", text: "#000000" },
  { background: "#0b3351", text: "#ffffff" },
  { background: "#f9dd50", text: "#000000" },
];

const defaultListItem: IListDefaults = {
  colors: defaultColors,
};

const developers: string[] = [
  "Andy",
  "Alex",
  "Andrew",
  "Andrei",
  "Richard",
  "Ben",
];
const qa: string[] = ["Mike", "Chris H"];
const devops: string[] = ["Mark", "Craig"];
const dataTeam: string[] = ["Euan"];
const tkg: string[] = [
  "Joel",
  "Clare",
  "Matt",
  "Tommy",
  "Spencer",
  "Jamil",
  "Rob",
];
const product: string[] = [
  "Natalie",
  "Michelle",
  "Craig",
  "Ben",
  "Andrei",
  "Mike",
  "Chris H",
  "Charlotte",
];
const procOps: string[] = ["Elva", "Helen", "Will", "Matt", "Jamil"];

const data: IList[] = [
  {
    ...defaultListItem,
    name: "All Company Standup",
    options: [
      "Sales",
      "Operations",
      "Company",
      "HR",
      "Platform",
      "Tech",
      "Marketing",
      "Finance",
      "BPO Src/Sply",
      "BPO Build/Ops",
    ],
  },
  {
    ...defaultListItem,
    name: "Tech Team",
    options: [...developers, ...qa, ...devops, ...product, ...dataTeam],
  },
  {
    ...defaultListItem,
    name: "TKG",
    options: [...tkg],
  },
  {
    ...defaultListItem,
    name: "Developers",
    options: [...developers],
  },
  {
    ...defaultListItem,
    name: "Demo",
    options: [...developers, ...qa],
  },
  {
    ...defaultListItem,
    name: "Product",
    options: [...product, ...qa],
  },

  {
    ...defaultListItem,
    name: "ProcOps",
    options: [...procOps],
  },
  {
    ...defaultListItem,
    name: "All Maistro",
    options: [
      ...developers,
      ...qa,
      ...devops,
      ...product,
      ...dataTeam,
      ...procOps,
      ...tkg,
      "Phil",
      "Chris M",
    ],
  },
  {
    ...defaultListItem,
    name: "Blank",
    options: [],
  },
];

export default data;
