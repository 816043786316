import React, { useState } from "react";
import { createUseStyles, useTheme } from "react-jss";
import { ITheme } from "styles/themes/types";
import { ICommonProps } from "components/shared";
import data, { IList } from "data";
import MaistroWheel from "components/maistro-wheel";
import Button from "components/Button/Button";
import MaistroSelect from "components/MaistroSelect/maistro-select";
import MaistroCheckBox from "components/MaistroCheckBox/maistro-check-box";
import NamePicker from "components/NamePicker/name-picker";
import MaistroText from "components/MaistroText/maistro-text";
import { faTrophy } from "@fortawesome/free-solid-svg-icons";
import Icon from "components/Icon/Icon";
import { getRandomWinnerImage } from "../../services/image";
import TextLoader from "components/TextLoader/text-loader";
import ReactSwipeEvents from "react-swipe-events";

const useStyles = createUseStyles((theme: ITheme) => ({
  container: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "start",
    gap: theme.spacing.large,
    padding: theme.spacing.medium,
    width: "100%",
    "@media (min-width: 720px)": {
      flexDirection: "row",
      gap: theme.spacing.xLarge,
    },
  },
  wheel: {
    flex: 1,
    display: "flex",
    flexDirection: "column",
    justifyContent: "start",
    gap: theme.spacing.medium,
    alignItems: "center",
    textAlign: "center",

    "@media (min-width: 720px)": {
      maxWidth: 400,
    },
  },
  gif: {
    width: "100%",
  },
  options: {
    flex: 1,
    display: "flex",
    flexDirection: "column",
    justifyContent: "start",
    gap: theme.spacing.medium,
    "@media (min-width: 720px)": {
      paddingLeft: theme.spacing.xLarge,
      paddingRight: theme.spacing.xLarge,
    },
  },
  add: {
    display: "flex",
    gap: theme.spacing.medium,
    justifyContent: "space-between",
    flexDirection: "column",
    "@media (min-width: 900px)": {
      flexDirection: "row",
    },
  },
  result: {
    ...theme.typography.headings.h1,
    color: theme.colors.primary,
    fontSize: 30,
    marginTop: theme.spacing.small,
    marginBottom: theme.spacing.large,
  },
  speedModeResult: {
    listStyle: "none",
    marginBottom: theme.spacing.medium,
    "& li": {
      padding: theme.spacing.xSmall,
    },
  },

  buttons: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    gap: theme.spacing.small,
    width: "100%",
    "@media (min-width: 720px)": {
      flexDirection: "row",
      width: "auto",
    },
  },
}));

interface IMainProps extends ICommonProps {}

const Main: React.FC<IMainProps> = (_props) => {
  const theme = useTheme<ITheme>();
  const classes = useStyles({ theme });

  const uniqueData = data.map((list) => {
    return {
      ...list,
      options: [...new Set(list.options)].sort(
        (a, b) => (a.toLowerCase() < b.toLowerCase() ? -1 : 1) //alphabetical
      ),
    };
  });
  const [activeList, setActiveList] = useState<IList>(uniqueData[0]);
  const [activeListID, setActiveListID] = useState<number>(0);
  const [mustSpin, setMustSpin] = useState(false);
  const [prizeNumber, setPrizeNumber] = useState(0);

  const [isSpeedMode, setIsSpeedMode] = useState(false);
  const [randomisedList, setRandomisedList] = useState<string[]>([]);
  const [isLoading, setIsLoading] = useState(true);

  const [addOptionText, setAddOptionText] = useState("");
  const [enableChangeList, setEnableChangeList] = useState(true);
  const [enableRemoveResultFromList, setEnableRemoveResultFromList] =
    useState(false);
  const [result, setResult] = useState("");

  const handleSpinClick = () => {
    setIsSpeedMode(false);
    const newPrizeNumber = Math.floor(
      Math.random() * activeList.options.length
    );
    setEnableChangeList(false);
    setPrizeNumber(newPrizeNumber);
    setMustSpin(true);
    setResult("");
  };

  const handleSpeedMode = () => {
    setIsSpeedMode(true);
    setIsLoading(true);
    const shuffle = (array: string[]) => {
      const newArray = [...array];
      return newArray.sort(() => Math.random() - 0.5);
    };
    const randomList = shuffle(activeList.options);
    setRandomisedList(randomList);
    setTimeout(() => setIsLoading(false), 10000);
  };

  const removeIDFromList = (list: IList, id: number) => {
    let options = [...list.options];
    options.splice(id, 1);
    return {
      ...activeList,
      options,
    };
  };

  const addOptionToList = () => {
    activeList.options.push(addOptionText);
    setAddOptionText("");
  };

  const handleSwipe = (e: any, originalX: any, endX: any) => {
    handleSpinClick();
  };

  return (
    <div className={classes.container}>
      <ReactSwipeEvents onSwipedRight={handleSwipe}>
        <div className={classes.wheel}>
          {result ? (
            <>
              <img
                className={classes.gif}
                alt='Result GIF'
                src={getRandomWinnerImage()}
              />
              <h1 className={classes.result}>
                <Icon color='#D4AF37' icon={faTrophy} /> The winner is...{" "}
                {result}
              </h1>
              <Button
                label='GO AGAIN'
                color='completed'
                size='large'
                onClick={() => setResult("")}
              ></Button>
            </>
          ) : (
            <>
              {!isSpeedMode && (
                <MaistroWheel
                  mustSpin={mustSpin}
                  prizeNumber={prizeNumber}
                  data={activeList}
                  onStopSpinning={(name) => {
                    setEnableChangeList(true);
                    setMustSpin(false);
                    setResult(name);
                    if (enableRemoveResultFromList) {
                      setActiveList(removeIDFromList(activeList, prizeNumber));
                    }
                  }}
                />
              )}
              {isSpeedMode && !isLoading && (
                <ul className={classes.speedModeResult}>
                  {randomisedList.map((item, id) => (
                    <li key={item}>
                      <MaistroCheckBox
                        label={`${id + 1}: ${item}`}
                        disabled={!enableChangeList}
                        checked={undefined}
                        onChange={() => {}}
                      />
                    </li>
                  ))}
                </ul>
              )}

              {isLoading && isSpeedMode && <TextLoader />}

              <div className={classes.buttons}>
                <Button
                  label='SPIN THE WHEEL'
                  color='completed'
                  size='large'
                  disabled={
                    !enableChangeList || activeList.options.length === 0
                  }
                  onClick={handleSpinClick}
                ></Button>
                <Button
                  label='SPEED MODE'
                  color='completed'
                  size='large'
                  disabled={
                    !enableChangeList || activeList.options.length === 0
                  }
                  onClick={handleSpeedMode}
                ></Button>
              </div>
            </>
          )}
        </div>
      </ReactSwipeEvents>
      <div className={classes.options}>
        <MaistroSelect
          name='Test'
          options={uniqueData.map((item, key) => ({
            value: key.toString(),
            label: item.name,
          }))}
          disabled={!enableChangeList}
          onChange={(e) => {
            if (enableChangeList) {
              const value: string = e.target.value;
              setActiveList(uniqueData[parseInt(value)]);
              setActiveListID(parseInt(value));
              setMustSpin(false);
            }
          }}
        />

        <MaistroCheckBox
          label='Remove winner after spin'
          disabled={!enableChangeList}
          checked={enableRemoveResultFromList}
          onChange={() => {
            setEnableRemoveResultFromList(!enableRemoveResultFromList);
          }}
        />
        <NamePicker
          list={activeList.options}
          disabled={!enableChangeList}
          onRemove={(id) => setActiveList(removeIDFromList(activeList, id))}
        />

        <div className={classes.add}>
          <MaistroText
            value={addOptionText}
            onEnter={addOptionToList}
            onChange={(e) => setAddOptionText(e.target.value)}
            disabled={!enableChangeList}
            placeholder='Item to add'
            fullWidth
          />

          <Button
            label='Add'
            size='medium'
            disabled={!enableChangeList || addOptionText === ""}
            onClick={addOptionToList}
          />
        </div>
        <div>
          <Button
            label='Reset list'
            size='medium'
            color='secondary'
            disabled={!enableChangeList}
            onClick={() => setActiveList(uniqueData[activeListID])}
          />
        </div>
      </div>
    </div>
  );
};

export default Main;
