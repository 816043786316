import React from "react";
import { createUseStyles, useTheme } from "react-jss";
import { ITheme } from "styles/themes/types";
import { ICommonProps } from "components/shared";
import classNames from "classnames";

const useStyles = createUseStyles((theme: ITheme) => ({
  container: {
    display: "flex",
    justifyContent: "left",
    alignItems: "center",
    gap: theme.spacing.small,
  },
  fullWidth: {
    width: "100%",
  },
  input: {
    ...theme.typography.content.input,
    borderStyle: "solid",
    borderWidth: theme.spacing.unit / 6,
    borderColor: theme.colors.primary,
    borderRadius: theme.spacing.xSmall,
    display: "inline-block",
    width: "100%",
    padding: theme.spacing.small,
  },
}));

interface ITextProps extends ICommonProps {
  value: string;
  disabled?: boolean;
  placeholder?: string;
  fullWidth?: boolean;
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onEnter?: () => void;
}

const MaistroText: React.FC<ITextProps> = (props) => {
  const theme = useTheme<ITheme>();
  const classes = useStyles({ theme });

  return (
    <div
      className={classNames(
        classes.container,
        props.fullWidth && classes.fullWidth
      )}
    >
      <input
        className={classNames(
          classes.input,
          props.fullWidth && classes.fullWidth
        )}
        type='text'
        onKeyUp={(e) => {
          if (e.key === "Enter" && props.onEnter) props.onEnter();
        }}
        disabled={props.disabled}
        value={props.value}
        placeholder={props.placeholder}
        onChange={props.onChange}
      />
    </div>
  );
};

export default MaistroText;
