import React from "react";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { createUseStyles, useTheme } from "react-jss";
import classNames from "classnames";
import { ITheme } from "styles/themes/types";
import { ICommonProps } from "components/shared";
import Icon from "components/Icon/Icon";

const useStyles = createUseStyles((theme: ITheme) => ({
  pill: {
    ...theme.typography.content.ctaButtonText,
    display: "inline-flex",
    justifyContent: "center",
    alignItems: "center",
    gap: theme.spacing.xSmall,
    background: theme.colors.status.completed,
    borderStyle: "solid",
    borderColor: "transparent",
    // borderRadius: theme.spacing.large,
    lineHeight: "12px",
    width: "fit-content",
    textAlign: "center",
  },

  small: {
    padding: `${theme.spacing.xSmall}px ${theme.spacing.small}px`,
    fontSize: 11,
    borderWidth: 1,
  },

  medium: {
    padding: `${theme.spacing.small}px ${theme.spacing.medium}px`,
    fontSize: 14,
    borderWidth: 1.5,
  },

  large: {
    padding: `${theme.spacing.medium}px ${theme.spacing.xLarge}px`,
    borderRadius: 100,
    borderWidth: 2,
    fontSize: 16,
  },

  internal: {
    backgroundColor: theme.colors.status.inProgress,
  },
}));

interface IPillProps extends ICommonProps {
  value: string;
  canRemove?: boolean;
  onRemove?: () => void;
  size?: "small" | "medium" | "large";
  background?: string;
  internal?: boolean;
}

const Pill: React.FC<IPillProps> = (props) => {
  const theme = useTheme<ITheme>();
  const classes = useStyles({ ...props, theme });

  const { value, canRemove, onRemove, size, testid } = props;

  return (
    <span
      className={classNames(
        classes.pill,
        props.internal && classes.internal,
        props.className,
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        { [classes[size!]]: size }
      )}
      data-component='Pill'
      data-testid={testid}
    >
      {value}
      {canRemove && (
        <Icon
          icon={faTimes}
          onClick={onRemove}
          testid={`${testid}-remove-button`}
        />
      )}
    </span>
  );
};

Pill.defaultProps = {
  size: "medium",
} as IPillProps;

export default Pill;
