import React, { ReactNode } from "react";
import { createUseStyles, useTheme } from "react-jss";
import { ITheme } from "styles/themes/types";
import logo from "assets/images/maistro/logo.png";
import classNames from "classnames";
import Toggle from "react-toggle";

const ERROR_BAR_HEIGHT = 40;
const HEADER_HEIGHT = 100;

const useStyles = createUseStyles((theme: ITheme) => ({
  layout: {
    ...theme.typography.base,
    color: theme.colors.textColor,
    minHeight: "100vh",
    display: "flex",
    flexDirection: "column",
    position: "relative",
    backgroundColor: theme.colors.background,
  },

  header: {
    backgroundColor: theme.colors.headingColor,
    color: theme.colors.white,
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    padding: theme.spacing.large,
    position: "fixed",
    width: "100%",
    minHeight: HEADER_HEIGHT,
    "& h1": {
      ...theme.typography.headings.h1,
      color: theme.colors.white,
      flexGrow: 1,
    },
    "& label": {
      display: "flex",
      alignItems: "center",
      flexDirection: "row-reverse",
      gap: 10,
    },
    zIndex: 99,
  },

  logo: {
    maxHeight: 24,
    marginBottom: 5,
    marginRight: theme.spacing.large,
  },

  error: {
    position: "fixed",
    top: HEADER_HEIGHT,
    width: "100%",
    height: ERROR_BAR_HEIGHT,
    backgroundColor: theme.colors.status.error,
    color: theme.colors.white,
    display: "flex",
    alignItems: "center",
    padding: theme.spacing.medium,
  },

  content: {
    ...theme.typography.content.project,
    color: theme.colors.textColor,
    backgroundColor: theme.colors.background,

    display: "flex",
    flexDirection: "column",
    gridGap: theme.spacing.large,
    "& > *": {
      width: "100%",
    },
    marginTop: HEADER_HEIGHT,

    padding: theme.spacing.medium,
    listStyle: "none",
  },

  contentError: {
    marginTop: HEADER_HEIGHT + ERROR_BAR_HEIGHT,
  },

  help: {
    cursor: "pointer",
    "&:hover": {
      color: theme.colors.hover,
    },
  },
  hideMobile: {
    display: "none",
    "@media (min-width: 720px)": {
      display: "inline-block",
    },
  },
}));

interface ILayoutProps {
  children?: ReactNode;
  errorText?: string;
  setDarkMode: (isDarkMode: boolean) => void;
  darkMode: boolean;
}

const BaseLayout: React.FC<ILayoutProps> = (props) => {
  const theme = useTheme<ITheme>();
  const classes = useStyles({ theme, ...props });
  const showErrorBar = props.errorText;
  return (
    <div
      data-component='BaseLayout'
      data-testid='base-layout'
      className={classes.layout}
    >
      <header className={classes.header}>
        <img className={classes.logo} src={logo} alt={theme.images.logo} />
        <h1>
          Wheel<span className={classes.hideMobile}>y Good Decision Maker</span>
        </h1>
        <label title='Dark mode'>
          <Toggle
            defaultChecked={props.darkMode}
            onChange={() => props.setDarkMode(!props.darkMode)}
          />
          <span className={classes.hideMobile}>Dark mode</span>
        </label>
      </header>
      {showErrorBar && (
        <div className={classes.error}>
          <p>{props.errorText}</p>
        </div>
      )}
      <main
        className={classNames(
          classes.content,
          showErrorBar && classes.contentError
        )}
      >
        {props.children}
      </main>
    </div>
  );
};

export default BaseLayout;
