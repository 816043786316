import AnimatedProgressBar from "components/AnimatedProgressBar/AnimatedProgressBar";
import React, { useEffect, useState } from "react";
import { createUseStyles, useTheme } from "react-jss";
import { ITheme } from "styles/themes/types";
const loadingTextOptions = [
  "Loading",
  "Calibrating flanges",
  "Doing timesheets for finance",
  "Making tea",
  "Finding nemo",
  "Alerting authorities",
  "Writing the Maistro theme song",
  "Making friendship bracelets",
  "Recycling used paper",
  "Making coffee",
  "Putting milk in the fridge",
  "Pairing socks",
  "Fetching data",
  "Sorting data",
  "Randomising requirements",
  "Consulting the sorting hat",
  "Taking the ring to Mordor",
  "Randomising estimations",
  "Searching for a new prime minister",
  "Reviewing buying habits",
];
interface IProps {}

const useStyles = createUseStyles((theme: ITheme) => ({
  loadingResults: {
    paddingTop: theme.spacing.xLarge,
    paddingBottom: theme.spacing.xLarge,
    display: "flex",
    flexDirection: "column",
    gap: theme.spacing.medium,
  },
}));

const TextLoader = (props: IProps) => {
  const theme = useTheme<ITheme>();
  const classes = useStyles({ theme });
  const [loadingText, setLoadingText] = useState(loadingTextOptions[0]);
  useEffect(() => {
    const updateText = () => {
      const randomIndex = Math.floor(Math.random() * loadingTextOptions.length);
      setLoadingText(loadingTextOptions[randomIndex]);
    };
    const interval = setInterval(updateText, 3000);
    return () => clearInterval(interval);
  }, []);
  return (
    <div className={classes.loadingResults}>
      <p>{loadingText}...</p>
      <AnimatedProgressBar />
    </div>
  );
};
export default TextLoader;
