import {
  ITheme,
  IThemeBreakpoints,
  IThemeComponentColors,
  IThemeComponentSpacing,
  IThemeComponentTypography,
  IThemeImages,
  IThemeSpacing,
  ITypography,
} from "styles/themes/types";

import logo from "assets/images/maistro/logo.png";
import squareLogo from "assets/images/maistro/logo-square.png";
import colorService from "services/colorService";

export const BaseBrandColors = {
  // primary brand
  blue: "#0C2F45",
  pink: "#DFA198",
  // sub-brand
  lightBlue: "#027FAE",
  salmon: "#F87E70",
  lightGrey: "#F8F8F8",
  // messages
  teal: "#519294",
  mustard: "#DFA242",
  burntOrange: "#C95A33",
  darkBlue: "#041D34",
  // misc
  grey: "#cccccc",
  red: "#FD1414",
  gold: "#F8AF00",
  // typography/common
  black: "#000000",
  white: "#FFFFFF",
  // statuses
  mediumGrey: "#9A9A9A",
  terracotta: "#AB5A4C",
  mediumBlue: "#184258",
};

const baseColors: IThemeComponentColors = {
  background: BaseBrandColors.white,
  textColor: BaseBrandColors.black,
  headingColor: BaseBrandColors.blue,
  primary: BaseBrandColors.blue,
  secondary: BaseBrandColors.pink,
  primaryAccent: BaseBrandColors.darkBlue,
  cta: BaseBrandColors.lightBlue,
  highlight: BaseBrandColors.salmon,
  hover: BaseBrandColors.lightBlue,
  stars: BaseBrandColors.gold,
  status: {
    completed: BaseBrandColors.teal,
    inProgress: BaseBrandColors.mustard,
    error: BaseBrandColors.burntOrange,
  },
  form: {
    error: BaseBrandColors.red,
    completed: BaseBrandColors.teal,
  },
  table: {
    chosen: BaseBrandColors.teal,
    received: colorService.toHexWithOpacity(BaseBrandColors.teal, 0.2),
    background: colorService.toHexWithOpacity(BaseBrandColors.grey, 0.2),
    hover: colorService.toHexWithOpacity(BaseBrandColors.grey, 0.6),
  },
  informative: BaseBrandColors.blue,
  accentBar: "transparent",
  black: BaseBrandColors.black,
  white: BaseBrandColors.white,
  grey: BaseBrandColors.grey,
  lightGrey: BaseBrandColors.lightGrey,
  logoBackground: "transparent",
  backdrop: colorService.toHexWithOpacity(BaseBrandColors.black, 0.5),
  approvalStatus: {
    approved: BaseBrandColors.teal,
    deactivated: BaseBrandColors.terracotta,
    pending: BaseBrandColors.mediumGrey,
    trial: BaseBrandColors.mediumBlue,
  },
  pills: {
    default: colorService.toHexWithOpacity(BaseBrandColors.mediumBlue, 0.7),
  },
};

const unit = 6;

const baseSpacing: IThemeSpacing = {
  unit,
  xSmall: unit,
  small: unit * 2,
  medium: unit * 3,
  large: unit * 4,
  xLarge: unit * 5,
  units: (quantity) => unit * quantity,
};

const baseComponentSpacing: IThemeComponentSpacing = {
  accentBarWidth: 0,
  navBarWidth: 200,
  navBarHeight: 48,
  desktopContentSpaceFromNav: 100,
  contentPadding: baseSpacing.large,
};

const baseBreakpoints: IThemeBreakpoints = {
  mobile: 550,
  tablet: 750,
};

const primaryTypography: ITypography = {
  fontFamily: "Sora, sans-serif",
  color: baseColors.textColor,
  lineHeight: "20px",
  fontSize: 12,
  fontWeight: 400,
};

const primaryHeaderTypography: ITypography = {
  ...primaryTypography,
  color: baseColors.headingColor,
};

const baseTypography: IThemeComponentTypography = {
  base: primaryTypography,
  headings: {
    h1: {
      ...primaryHeaderTypography,

      fontSize: 20,
      fontWeight: 600,
      lineHeight: "28px",
    },
    h2: {
      ...primaryHeaderTypography,

      fontSize: 18,
      fontWeight: 600,
    },
    h3: {
      ...primaryHeaderTypography,

      fontSize: 16,
      fontWeight: 400,
    },
    h4: {
      ...primaryHeaderTypography,

      fontSize: 14,
      fontWeight: 400,
    },
    h5: {
      ...primaryHeaderTypography,

      fontSize: 12,
      fontWeight: 400,
    },
    h6: {
      ...primaryHeaderTypography,
      color: baseColors.primaryAccent,
      fontSize: 11,
      fontWeight: 400,
    },
  },
  content: {
    strapline: {
      ...primaryTypography,
      lineHeight: "24px",
      fontSize: 16,
      fontWeight: 400,
    },
    microcopy: {
      ...primaryTypography,
      lineHeight: "22px",
      fontSize: 14,
      fontWeight: 400,
    },
    project: {
      ...primaryTypography,
      lineHeight: "22px",
      fontSize: 14,
      fontWeight: 400,
    },
    small: {
      ...primaryTypography,
      lineHeight: "18px",
      fontSize: 11,
      fontWeight: 400,
    },
    error: {
      ...primaryTypography,
      color: baseColors.form.error,
      lineHeight: "18px",
      fontSize: 11,
      fontWeight: 400,
    },
    ctaButtonText: {
      ...primaryTypography,
      color: baseColors.background,
      fontSize: 16,
      fontWeight: 400,
    },
    input: {
      ...primaryTypography,
      lineHeight: "24px",
      fontSize: 14,
      fontWeight: 400,
    },
  },
};

const baseImages: IThemeImages = {
  logo,
  squareLogo,
};

const baseTheme: ITheme = {
  colors: baseColors,
  spacing: baseSpacing,
  componentSpacing: baseComponentSpacing,
  breakpoints: baseBreakpoints,
  typography: baseTypography,
  images: baseImages,
};
export default baseTheme;
