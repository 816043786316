import React from "react";
import { createUseStyles, useTheme } from "react-jss";
import classNames from "classnames";
import { ICommonProps } from "components/shared";
import { ITheme } from "styles/themes/types";

interface IStyles {
  theme: ITheme;
  props: IAnimatedProgressBarProps;
}

export interface IAnimatedProgressBarProps extends ICommonProps {
  duration?: number;
  backgroundColor?: string;
  foregroundColor?: string;
  height?: number;
}

const useStyles = createUseStyles({
  "@keyframes progress": {
    "0%": { width: "0%" },
    "50%": { width: "100%" },
    "100%": { width: "0%" },
  },
  component: (styles: IStyles) => ({
    display: "inline-block",
    backgroundColor: styles.props.backgroundColor ?? styles.theme.colors.white,
    width: "100%",
    height: styles.props.height ?? 15,
    borderRadius: 100,
    overflow: "hidden",
    position: "relative",
    "& span": {
      display: "inline-block",
      position: "absolute",
      left: 0,
      backgroundColor: styles.props.foregroundColor ?? styles.theme.colors.cta,
      width: 0,
      height: styles.props.height ?? 15,
      borderRadius: 100,
      animationName: "$progress",
      animationTimingFunction: "ease-in-out",
      animationDuration: styles.props.duration
        ? styles.props.duration * 2
        : 5000,
      animationIterationCount: "infinite",
    },
  }),
});

const AnimatedProgressBar: React.FC<IAnimatedProgressBarProps> = (props) => {
  const theme = useTheme<ITheme>();
  const classes = useStyles({ theme, props });

  return (
    <span
      className={classNames(classes.component, props.className)}
      data-component='AnimatedProgressBar'
      data-testid={props.testid}
    >
      <span></span>
    </span>
  );
};

export default AnimatedProgressBar;
