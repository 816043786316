export const getRandomWinnerImage = () => {
  const URLS: string[] = [
    "https://media.giphy.com/media/xUOwGmG2pRfFZUmdVe/giphy.gif",
    "https://media.giphy.com/media/boNNxI4tHdez3kThsn/giphy.gif",
    "https://media.giphy.com/media/gxEAWPmfEzYdAUFAi9/giphy.gif",
    "https://media.giphy.com/media/26u4exk4zsAqPcq08/giphy.gif",
    "https://media.giphy.com/media/zlRvmxoLXbeT4ilu0a/giphy.gif",
    "https://media.giphy.com/media/FMapondVtL2Fi/giphy.gif",
    "https://media.giphy.com/media/Fb6u1MWpND4oZ5XHBQ/giphy.gif",
    "https://media.giphy.com/media/l4hLwMmFVBOAKF3EI/giphy.gif",
    "https://media.giphy.com/media/DmzQ4iPMyUScw/giphy.gif",
    "https://media.giphy.com/media/fXLHAkIvVuggw/giphy.gif",
    "https://media.giphy.com/media/sFZ7jXF6KHu8g/giphy.gif",
    "https://media.giphy.com/media/sFZ7jXF6KHu8g/giphy.gif",
    "https://media.giphy.com/media/26xBO9nlUfChvTPcQ/giphy.gif",
    "https://media.giphy.com/media/cltBzcfPvdwGYxyhzG/giphy.gif",
    "https://media.giphy.com/media/26tPcgtbhhbU88U2A/giphy.gif",
    "https://media.giphy.com/media/l378e0Nha3ExLVEbK/giphy.gif",
    "https://media.giphy.com/media/3oEjI7B6kwipM85iJW/giphy.gif",
    "https://media.giphy.com/media/VGlstt2XDHjcsddHYp/giphy.gif",
    "https://media.giphy.com/media/3on2asMnQMuafXiXX0/giphy.gif",
    "https://media.giphy.com/media/KEVNWkmWm6dm8/giphy.gif",
    "https://media.giphy.com/media/088w8xc5HVkosnePWq/giphy.gif",
    "https://media.giphy.com/media/HipvJ7sN8OFVmNCRsD/giphy.gif",
    "https://media.giphy.com/media/l46Ca8TUDCnHe6WoU/giphy.gif",
    "https://media.giphy.com/media/QaFsFZCy9CxJHts5uH/giphy.gif",
    "https://media.giphy.com/media/iOGjqLSf2o18GEeC9U/giphy.gif",
    "https://media.giphy.com/media/l4FGrqdeYGZfG9JHG/giphy.gif",
    "https://media.giphy.com/media/3EiJPOOt1UNViPn4cb/giphy.gif",
    "https://media.giphy.com/media/kfRlTZDvhLCPvOEey8/giphy.gif",
    "https://media.giphy.com/media/6X4dFfGdXx7qSy8829/giphy.gif",
    "https://media.giphy.com/media/nV92wySC3iMGhAmR71/giphy.gif",
    "https://media.giphy.com/media/IwAZ6dvvvaTtdI8SD5/giphy.gif",
    "https://media.giphy.com/media/artj92V8o75VPL7AeQ/giphy.gif",
    "https://media.giphy.com/media/35HTaxVJWzp2QOShct/giphy.gif",
    "https://media.giphy.com/media/sVnKj2wDhUTsFKFWhx/giphy.gif",
    "https://media.giphy.com/media/kyLYXonQYYfwYDIeZl/giphy.gif",
    "https://media.giphy.com/media/kyLYXonQYYfwYDIeZl/giphy.gif",
    "https://media.giphy.com/media/S9iMXx6Lqrr5mJoMxC/giphy.gif",
    "https://media.giphy.com/media/26tPplGWjN0xLybiU/giphy.gif",
    "https://media.giphy.com/media/MTclfCr4tVgis/giphy.gif",
    "https://media.giphy.com/media/6oMKugqovQnjW/giphy.gif",
    "https://media.giphy.com/media/l0MYt5jPR6QX5pnqM/giphy.gif",
    "https://media.giphy.com/media/5PSPV1ucLX31u/giphy.gif",
    "https://media.giphy.com/media/6nuiJjOOQBBn2/giphy.gif",
    "https://media.giphy.com/media/ZFTKZ8zwj38gE/giphy.gif",
    "https://media.giphy.com/media/Hd3GXtH7xs1CU/giphy.gif",
    "https://media.giphy.com/media/msKNSs8rmJ5m/giphy.gif",
    "https://media.giphy.com/media/LSNqpYqGRqwrS/giphy.gif",
    "https://media.giphy.com/media/26gN16cJ6gy4LzZSw/giphy.gif",
    "https://media.giphy.com/media/102VfCWF40oAuI/giphy.gif",
    "https://media.giphy.com/media/xT9GEDy5eIjBnCKiEU/giphy.gif",
    "https://media.giphy.com/media/7zMkk1aiQVonuZQKi6/giphy.gif",
    "https://media.giphy.com/media/zujkOi1kkLRzG/giphy.gif",
    "https://media.giphy.com/media/yoJC2COHSxjIqadyZW/giphy.gif",
    "https://media.giphy.com/media/lz24Z42jLcTa8/giphy.gif",
    "https://media.giphy.com/media/1iW2l9nDLKqWbPxK/giphy.gif",
    "https://media.giphy.com/media/cOKjNdJDbqNCm4n0Jm/giphy.gif",
    "https://media.giphy.com/media/ZUomWFktUWpFu/giphy-downsized-large.gif",
  ];
  const randomImageID = Math.floor(Math.random() * URLS.length);
  return URLS[randomImageID];
};
