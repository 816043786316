/* eslint-disable @typescript-eslint/no-non-null-assertion */
import React from "react";
import { createUseStyles, useTheme } from "react-jss";
import { IconDefinition } from "@fortawesome/fontawesome-svg-core";
import { faAngleRight } from "@fortawesome/free-solid-svg-icons";
import classNames from "classnames";
import { ITheme } from "styles/themes/types";
import Icon from "components/Icon/Icon";
import { ICommonProps } from "components/shared";
import BetaPill from "components/Pill/BetaPill";

const useStyles = createUseStyles((theme: ITheme) => ({
  component: {},

  btn: {
    ...theme.typography.content.ctaButtonText,
    cursor: "pointer",
    background: theme.colors.cta,
    borderStyle: "solid",
    borderColor: "transparent",
    borderRadius: 100,
    whiteSpace: "nowrap",

    "&[disabled]": {
      cursor: "default",
      background: theme.colors.grey,
      borderColor: "transparent",
      "&:hover": {
        backgroundColor: theme.colors.grey,
        borderColor: "transparent",
      },
    },

    "& span": {
      display: "inline-flex",
      justifyContent: "center",
      alignItems: "center",
      gap: theme.spacing.xSmall,
    },
  },

  caretOffset: {
    "& span": { paddingLeft: "0.6em" },
  },

  primary: {
    backgroundColor: theme.colors.cta,
    color: theme.colors.background,
    borderColor: theme.colors.cta,
    "&:hover:not([disabled])": {
      backgroundColor: theme.colors.primary,
      borderColor: theme.colors.white,
      color: theme.colors.background,
    },
  },

  secondary: {
    backgroundColor: theme.colors.primaryAccent,
    color: theme.colors.background,
    borderColor: theme.colors.primary,
    "&:hover:not([disabled])": {
      backgroundColor: theme.colors.primary,
      borderColor: theme.colors.primary,
      color: theme.colors.background,
    },
  },

  completed: {
    backgroundColor: theme.colors.status.completed,
    color: theme.colors.white,
    borderColor: theme.colors.status.completed,
    "&:hover:not([disabled])": {
      backgroundColor: theme.colors.primary,
      borderColor: theme.colors.white,
      color: theme.colors.background,
    },
  },

  xSmall: {
    padding: `${theme.spacing.xSmall}px ${theme.spacing.small}px`,
    minWidth: 60,
    fontSize: 10,
    borderWidth: 1,
  },

  small: {
    padding: `${theme.spacing.xSmall}px ${theme.spacing.small}px`,
    minWidth: 80,
    fontSize: 12,
    borderWidth: 1,
  },

  medium: {
    padding: `${theme.spacing.small}px ${theme.spacing.medium}px`,
    minWidth: 100,
    fontSize: 14,
    borderWidth: 1.5,
  },

  large: {
    padding: `${theme.spacing.medium}px ${theme.spacing.xLarge}px`,
    borderRadius: 100,
    borderWidth: 2,
    fontSize: 16,
    minWidth: 150,
  },

  w100: {
    width: "100%",
  },

  notification: {
    display: "inline-flex",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: theme.colors.form.error,
    padding: 8,
    width: "1.4em",
    height: "1.4em",
    fontSize: "0.7em",
    borderRadius: "50%",
  },

  "@media (max-width: 550px)": {
    xSmall: {
      padding: theme.spacing.xSmall,
    },
    small: {
      padding: theme.spacing.xSmall,
    },
    medium: {
      padding: theme.spacing.small,
    },
    large: {
      padding: theme.spacing.medium,
    },
  },
}));

export interface IButtonProps extends ICommonProps {
  label: string;
  onClick?: () => void;
  disabled?: boolean;
  icon?: IconDefinition;
  endIcon?: IconDefinition;
  caret?: boolean;
  notifications?: number;
  type?: "submit" | "reset" | "button";
  color?: "primary" | "secondary" | "completed";
  size?: "xSmall" | "small" | "medium" | "large";
  w100?: boolean;
  beta?: boolean;
}

const Button = (props: IButtonProps) => {
  const theme = useTheme<ITheme>();
  const classes = useStyles({ theme });

  const {
    label,
    onClick,
    disabled,
    icon,
    caret,
    notifications,
    type,
    color,
    size,
    w100,
    beta,
    endIcon,
    testid,
  } = props;

  const textStrings = {
    noTitle: "",
  };

  return (
    <button
      disabled={disabled ?? false}
      className={classNames(
        classes.btn,
        props.className,
        { [classes.w100]: w100 },
        { [classes.caretOffset]: caret },
        { [classes[color!]]: color },
        { [classes[size!]]: size }
      )}
      type={type ?? "button"}
      onClick={onClick}
      data-component='Button'
      data-testid={testid}
    >
      <span>
        {icon && <Icon icon={icon} size={size ?? "medium"} />}
        {label ?? textStrings.noTitle}
        {endIcon && <Icon icon={endIcon} size={size ?? "medium"} />}
        {notifications !== undefined && notifications > 0 && (
          <span
            className={classes.notification}
            data-testid={`${testid}-notifications`}
          >
            {notifications}
          </span>
        )}
        {caret && <Icon icon={faAngleRight} />}
        {beta && <BetaPill />}
      </span>
    </button>
  );
};

Button.defaultProps = {
  size: "medium",
  color: "primary",
} as IButtonProps;

export default Button;
