import baseTheme from "styles/themes/base/base";
import { ITheme } from "styles/themes/types";

const dark: ITheme = {
  ...baseTheme,
  images: {
    ...baseTheme.images,
  },
  typography: {
    ...baseTheme.typography,
    base: {
      ...baseTheme.typography.base,
      color: baseTheme.colors.white,
    },
  },
  colors: {
    ...baseTheme.colors,
    background: "#222",
    primary: baseTheme.colors.white,
    primaryAccent: baseTheme.colors.lightGrey,
    textColor: baseTheme.colors.white,
    logoBackground: baseTheme.colors.white,
  },
};

export default dark;
