import { ThemeProvider } from "react-jss";
import maistroTheme from "styles/themes/maistro";
import dark from "styles/themes/dark";
import React, { useState } from "react";
import Main from "components/Main/main";
import BaseLayout from "components/Layout/BaseLayout";

function App() {
  const [darkMode, setDarkMode] = useState(false);

  return (
    <ThemeProvider theme={darkMode ? dark : maistroTheme}>
      <BaseLayout
        darkMode={darkMode}
        setDarkMode={(mode) => {
          setDarkMode(mode);
        }}
      >
        <Main />
      </BaseLayout>
    </ThemeProvider>
  );
}

export default App;
