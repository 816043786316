import React from "react";
import { createUseStyles, useTheme } from "react-jss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faToilet } from "@fortawesome/free-solid-svg-icons";
import { IconDefinition } from "@fortawesome/fontawesome-svg-core";

import { ICommonProps } from "components/shared";
import { ITheme } from "styles/themes/types";
import classNames from "classnames";

const useStyles = createUseStyles((theme: ITheme) => ({
  component: {
    display: "inline-flex",
    justifyContent: "center",
    alignItems: "center",
    color: "inherit",
    height: "1.5em",
    width: "1.5em",
  },

  round: {
    borderRadius: "50%",
  },

  xSmall: {
    fontSize: 10,
  },
  small: {
    fontSize: 15,
  },
  medium: {
    fontSize: 20,
  },
  large: {
    fontSize: 27,
  },
  marginRight: {
    marginRight: theme.spacing.xSmall,
  },
  marginLeft: {
    marginleft: theme.spacing.xSmall,
  },
  clickable: {
    cursor: "pointer",
  },
}));

interface IIconProps extends ICommonProps {
  icon: IconDefinition;
  alt?: string;
  backgroundColor?: string;
  color?: string;
  round?: boolean;
  padRight?: boolean;
  padLeft?: boolean;
  size?: "xSmall" | "small" | "medium" | "large";
  onClick?: (event: React.MouseEvent<HTMLDivElement>) => void;
}

const Icon: React.FC<IIconProps> = (props) => {
  const theme = useTheme<ITheme>();
  const classes = useStyles({ ...props, theme });

  return (
    <div
      className={classNames(
        classes.component,
        props.className,
        { [classes.round]: props.round },
        { [classes.marginRight]: props.padRight },
        { [classes.marginLeft]: props.padLeft },
        { [classes.clickable]: props.onClick },
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        { [classes[props.size!]]: props.size }
      )}
      style={{ color: props.color ? props.color : "inherit" }}
      onClick={props.onClick}
      data-component='Icon'
      data-testid={props.testid}
    >
      <FontAwesomeIcon
        title={props.alt}
        icon={props.icon ? props.icon : faToilet}
      />
    </div>
  );
};

export default Icon;
