import React from "react";
import { createUseStyles, useTheme } from "react-jss";
import { ITheme } from "styles/themes/types";
import { ICommonProps } from "components/shared";
import Icon from "components/Icon/Icon";
import { faTimes } from "@fortawesome/free-solid-svg-icons";

const useStyles = createUseStyles((theme: ITheme) => ({
  container: {
    listStyle: "none",
    borderStyle: "solid",
    borderWidth: theme.spacing.unit / 6,
    borderColor: theme.colors.primary,
    borderRadius: theme.spacing.xSmall,
    padding: theme.spacing.small,
    width: "100%",
    "& > li": {
      display: "flex",
      justifyContent: "space-between",
      "&:hover": {
        backgroundColor: theme.colors.highlight,
      },
    },
  },
  label: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    width: "100%",
    paddingLeft: theme.spacing.xSmall,
    "&:hover": {
      backgroundColor: theme.colors.white,
    },
  },
  button: {
    border: "none",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    fontSize: theme.spacing.medium,
    "&[disabled]:hover": {
      color: "#AAA",
      cursor: "initial",
    },
    "&:hover": {
      color: theme.colors.white,
      backgroundColor: theme.colors.primary,
      cursor: "pointer",
    },
  },
}));

interface INamePickerProps extends ICommonProps {
  list: Array<string>;
  disabled?: boolean;
  onRemove: (id: number) => void;
}

const NamePicker: React.FC<INamePickerProps> = (props) => {
  const theme = useTheme<ITheme>();
  const classes = useStyles({ theme });

  return (
    <ul className={classes.container}>
      {props.list.map((label, key) => (
        <li key={`${label}`}>
          <label className={classes.label}>{label}</label>
          <button
            className={classes.button}
            title={`Remove ${label}`}
            disabled={props.disabled}
            onClick={() => props.onRemove(key)}
          >
            <Icon icon={faTimes} />
          </button>
        </li>
      ))}
    </ul>
  );
};

export default NamePicker;
